import './App.css';
import React, {Component,lazy,Suspense, useEffect} from 'react';
import {Button} from "antd";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {_fetch} from "./Common/common";
import "antd/dist/antd.css";
import ProgressBar from "./Components/ProgressPage";
//Pages
import Template from "./Components/Template";

const addNonce = () => {

    const scriptTags = document.getElementsByTagName('script');
    Array.from(scriptTags).forEach(script => {
        const nonce = generateNonce();
        script.setAttribute('nonce', nonce);
    });

    function generateNonce() {
        const charset = 'asdafsadfcaveswdwrweewfr55324232rgwswe';
        return charset;
    }
}
const MainPage = lazy(() => import("./Pages/MainPage").then( module => {
    addNonce()
    return module }));
const Faq = lazy(() => import("./Pages/Faq").then( module => {
    addNonce()
    return module }));
const Login = lazy(() => import("./Pages/Login").then( module => {
    addNonce()
    return module }));
const RegisterAdvertiser = lazy(() => import("./Pages/RegisterAdvertiser").then( module => {
    addNonce()
    return module }));
const RegisterInfluencer = lazy(() => import("./Pages/RegisterInfluencer").then( module => {
    addNonce()
    return module }));
const InfluencerList = lazy(() => import("./Pages/InfluencerList").then( module => {
    addNonce()
    return module }));
const ProjectList = lazy(() => import("./Pages/ProjectList").then( module => {
    addNonce()
    return module }));
const CreateProject = lazy(() => import("./Pages/CreateProject").then( module => {
    addNonce()
    return module }));
const Profile = lazy(() => import("./Pages/Profile").then( module => {
    addNonce()
    return module }));
const TransactionHistory = lazy(() => import("./Pages/TransactionHistory").then( module => {
    addNonce()
    return module }));
const AdvertiserProject = lazy(() => import("./Pages/AdvertiserProjectList").then( module => {
    addNonce()
    return module }));
const ProjectDetail = lazy(() => import("./Pages/ProjectDetail").then( module => {
    addNonce()
    return module }));
const AfterUserConfirmation = lazy(() => import("./Pages/AfterUserConfirmation").then( module => {
    addNonce()
    return module }));
const BeforeUserConfirmation = lazy(() => import("./Pages/BeforeUserConfirmation").then( module => {
    addNonce()
    return module }));
const ResetPassword = lazy(() => import("./Pages/ResetPassword").then( module => {
    addNonce()
    return module }));
const Workspace = lazy(() => import("./Pages/Workspace").then( module => {
    addNonce()
    return module }));
const DisputeWorkSpace = lazy(() => import("./Pages/DisputeWorkspace").then( module => {
    addNonce()
    return module }));
const RequestResetPassword = lazy(() => import("./Pages/RequestResetPassword").then( module => {
    addNonce()
    return module }));
const About = lazy(() => import("./Pages/About").then( module => {
    addNonce()
    return module }));
const Privacy = lazy(() => import("./Pages/Privacy").then( module => {
    addNonce()
    return module }));
const Terms = lazy(() => import("./Pages/Terms").then( module => {
    addNonce()
    return module }));
const FeesAndCharges = lazy(() => import("./Pages/FeesAndCharges").then( module => {
    addNonce()
    return module }));
const ContactUs = lazy(() => import("./Pages/ContactUs").then( module => {
    addNonce()
    return module }));
const NotificationList = lazy(() => import("./Pages/NotificationList").then( module => {
    addNonce()
    return module }));

const ViewProfile = lazy(() => import("./Pages/ViewProfile").then( module => {
    addNonce()
    return module }));
const InfluencerBidList = lazy(() => import("./Pages/InfluencerBidList").then( module => {
    addNonce()
    return module }));
const OptOutPromotional = lazy(() => import("./Pages/OptOutPromotional").then( module => {
    addNonce()
    return module }));

const ProjectOverviewBeforeSubmit = lazy(() => import("./Pages/projectOverviewBeforeSubmit").then( module => {
    addNonce()
    return module }));
const PublishProjectNewProject = lazy(() => import("./Pages/publishProjectNewProject").then( module => {
    addNonce()
    return module }));
const SuccessPageNewProject = lazy(() => import("./Pages/successPageNewProject").then( module => {
    addNonce()
    return module }));
const WorkspaceList = lazy(() => import("./Pages/WorkspaceList").then( module => {
    addNonce()
    return module }));
const InfluencerLogin = lazy(() => import("./Pages/InfluencerLogin").then( module => {
    addNonce()
    return module }));
const AdvertiserLogin = lazy(() => import("./Pages/AdvertiserLogin").then( module => {
    addNonce()
    return module }));
const PageNotFound = lazy(() => import("./Components/PageNotFound").then( module => {
    addNonce()
    return module }));
const FirstTimeLogin = lazy(() => import("./Pages/FirstTimeLogin").then( module => {
    addNonce()
    return module }));
const Seo = lazy(() => import("./Pages/SEO").then( module => {
    addNonce()
    return module }));

const YourEmailFailedToReceive_FirstTimeLogin = lazy(() => import("./Pages/YourEmailFailedToReceive_FirstTimeLogin").then( module => {
    addNonce()
    return module }));
const ResetMigrationNewSystem = lazy(() => import("./Pages/ResetMigrationNewSystem").then( module => {
    addNonce()
    return module }));




export default class App extends Component {

    state = {
        'authorized': false,
        'initDone': false,
        'displayName': '',
        'username': '',
        'navHistory': '',
        'countdown': 100000,
        'countdownStarted': false,
        'refreshDesign': '',
    }
    lazyload = () => {

    }


    refreshPageDesign = () => {

        return (
            <div>
                <div className="fixed bg-gray-200 h-screen w-full z-[7000]">
                </div>
                <div className="relative flex justify-center items-center h-screen w-full z-[10000]">
                    <div className="p-6">
                        {'We have just updated our website, please click update to refresh to the latest content...'}

                            <Button loading = { this.state.countdownStarted } onClick={() => {

                                    this.countdown = setInterval(() => {
                                        if (this.state.countdown > 0) {

                                            this.setState({
                                                countdown: this.state.countdown-1,
                                                countdownStarted: true
                                            })

                                            if (this.state.countdown === 0) {
                                                this.setState({
                                                    countdown: 'redirecting ....',
                                                })
                                                this.countdown = undefined;
                                                sessionStorage.setItem('version_kolspire',this.state.refreshDesign)
                                                sessionStorage.setItem('version_loaded_kolspire','loaded');
                                                window.location.reload()
                                            }
                                        }
                                    }, 1000)

                              }
                            }>
                            <span className="text-center">
                                UPDATE PAGE
                            </span>
                            </Button>

                    </div>
                </div>
            </div>
        )
    }


    componentDidMount() {

        this.countdown = '';
        this.checkSession();

    }
    componentWillUnmount() {
        localStorage.setItem('linksSaved',null);
        localStorage.setItem('history',null);

       this.countdown = undefined;
    }

    checkSession = () => {
        _fetch({
            api : 'user/checkSession',
        }).then(data => {
            let logoutError=["Session Expired","Unauthorized"];
            if (logoutError.includes(data.msg)) {
                localStorage.removeItem('jwt')
            }else if(data.status === 'success'){
                this.setState({
                    authorized: true,
                    role: data.role,
                    displayName: data.name,
                    username: data.username,
                })
            }

            this.setState({
                initDone: true,
            })
        })
    }

    metaDataAttachment = (url) => {
        let a = 'Kolspire';
    }
    render() {
        let globalData = {
            role: this.state.role,
            authorized: this.state.authorized,
            displayName: this.state.displayName,
            username: this.state.username,
            navHistory: window.localStorage.getItem('saveNavHistory'),
            metaData: this.metaData
        }

        return (
            <>
            {  this.state.countdown < 4 && this.state.countdown > 0 &&
                this.refreshPageDesign()
            }
            {
            this.state.initDone &&
            <Router>
                <Switch>
                    <Route path='/errorFirstTimeLogin'>
                        { this.metaDataAttachment('errorFirstTimeLogin') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData} pageTitle='You have not receive our email(Kolspire Migration New System)'
                                content={ () => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                        <YourEmailFailedToReceive_FirstTimeLogin />
                                    </Suspense>
                                )}
                            />
                        </Suspense>
                    </Route>
                    <Route path='/firstTimeLogin'>
                        { this.metaDataAttachment('firstTimeLogin') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData} pageTitle='Kolspire Migration New System'
                                content={ () => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                            <FirstTimeLogin />
                                    </Suspense>
                                )}
                            />
                        </Suspense>
                    </Route>
                    <Route path='/unsubscribe?version/optOutPromotional/:userid&:email'>
                        { this.metaDataAttachment('unsubscribe') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData} pageTitle='Promotional Email'
                                content={ () => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                           <OptOutPromotional/>
                                    </Suspense>
                                )}

                            />
                        </Suspense>
                    </Route>
                    <Route path='/about'>
                        { this.metaDataAttachment('about') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData}
                                pageTitle='About Us'
                                content={ () => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                           <About/>
                                    </Suspense>
                                )}
                            />
                        </Suspense>
                    </Route>
                    <Route path='/influencerLogin'>
                        { this.metaDataAttachment('influencerLogin') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Influencer Login'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                          <InfluencerLogin authorized={this.state.authorized}
                                                                role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path='/advertiserLogin'>
                        { this.metaDataAttachment('advertiserLogin') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                        <Template globalData={globalData} pageTitle='Advertiser login'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                          <AdvertiserLogin authorized={this.state.authorized}
                                                                role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>


                    <Route path='/overviewNewProject/:projectId'>
                        { this.metaDataAttachment('overviewNewProject') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                 <Template globalData={globalData} pageTitle='Overview New Projects'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                          <ProjectOverviewBeforeSubmit role={this.state.role}
                                                                            authorized={this.state.authorized}/>
                                      </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    {this.state.role === 'advertiser' &&
                        <Route path='/workspaceList'>
                            { this.metaDataAttachment('workspaceList') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Workspace Listing'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                  <WorkspaceList role={this.state.role}/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {this.state.role === 'influencer' &&
                        <Route path='/workspaceList'>
                            { this.metaDataAttachment('workspaceList') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Influencer Bid List'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <InfluencerBidList role={this.state.role}/>
                                          </Suspense>
                                      )}/>
                            </Suspense>
                        </Route>
                    }
                    <Route path='/privacy'>
                        { this.metaDataAttachment('privacy') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                               <Template globalData={globalData} pageTitle='Privacy Policy'
                                     content={ () => (
                                         <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                             <ProgressBar />
                                              <Privacy/>
                                         </Suspense>
                                     )}/>
                        </Suspense>
                    </Route>
                    <Route path='/terms'>
                        { this.metaDataAttachment('terms') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Terms and Conditions'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <Terms/>
                                          </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path='/feesAndCharges'>
                        { this.metaDataAttachment('feesAndCharges') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Fees and Charges'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <FeesAndCharges/>
                                          </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path='/contactUs'>
                        { this.metaDataAttachment('contactUs') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                           <Template globalData={globalData} pageTitle='Contact Us'
                                     content={ () => (
                                         <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                             <ProgressBar />
                                             <ContactUs/>
                                         </Suspense>
                                         )}/>
                        </Suspense>
                    </Route>
                    <Route path='/login'>
                        { this.metaDataAttachment('login') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                              <Template globalData={globalData} pageTitle='Login'
                              content={ () => (
                                  <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                      <ProgressBar />
                                         <Login authorized={this.state.authorized} role={this.state.role}/>
                                  </Suspense>
                              )}/>
                        </Suspense>
                    </Route>
                    <Route path='/newProject/loginToSee/:projectid'>
                        { this.metaDataAttachment('newProject') }
                         <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                 <Template globalData={globalData} pageTitle='New Project'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                         <Login authorized={this.state.authorized} role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                         </Suspense>
                    </Route>
                    <Route path='/changeProfileSettings/:settings'>
                        { this.metaDataAttachment('changeProfileSettings') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Change Profile Settings'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                           <Login authorized={this.state.authorized} role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path='/otherPages/:redirect'>
                        { this.metaDataAttachment('otherPages') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Redirect'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                              <Login authorized={this.state.authorized} role={this.state.role}/>
                                      </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path='/requestResetPassword'>
                        { this.metaDataAttachment('requestResetPassword') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Request Reset Password'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <RequestResetPassword/>
                                          </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/resetPassword/:hash']}>
                        { this.metaDataAttachment('resetPassword') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Reset Password' content={ () => (
                                <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                    <ProgressBar />
                                      <ResetPassword/>
                                </Suspense>
                            )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/resetMigrationNewSystem/:hash']}>
                        { this.metaDataAttachment('resetMigrationNewSystem') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                              <Template globalData={globalData} pageTitle='Set New Password Kolspire'
                                  content={ () => (
                                  <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                      <ProgressBar />
                                      <ResetMigrationNewSystem/>
                                  </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/beforeConfirmation/:email/:username']}>
                        { this.metaDataAttachment('beforeConfirmation') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Before Confirmation'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                      <BeforeUserConfirmation/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/afterConfirmation/:hash']}>
                        { this.metaDataAttachment('afterConfirmation') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='After Confirmation'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                              <AfterUserConfirmation/>)
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path='/faq'>
                        { this.metaDataAttachment('faq') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                        <Template globalData={globalData} pageTitle='Frequently Asked Question'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                        <Faq/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/registerInfluencer/:username', '/registerInfluencer']}>
                        { this.metaDataAttachment('registerInfluencer') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Sign Up for Influencer Account'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                          <ProgressBar />
                                            <RegisterInfluencer authorized={ this.state.authorized } role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path={['/registerAdvertiser/:username', '/registerAdvertiser']}>
                        { this.metaDataAttachment('registerAdvertiser') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Sign Up for Advertiser Account'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                                <RegisterAdvertiser authorized={ this.state.authorized } role={this.state.role}/>
                                          </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path={'/influencer'}>
                        { this.metaDataAttachment('influencer') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Influencer Listing'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                                <InfluencerList role={this.state.role}/>
                                          </Suspense>
                                      )}/>
                        </Suspense>
                    </Route>
                    <Route path={'/project'}>
                        { this.metaDataAttachment('project') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Project Listing'
                                  content={ () => (
                                      <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                             <ProgressBar />
                                             <ProjectList authorized={this.state.authorized} role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>
                    <Route path={'/projectDetail/:id'}>
                        { this.metaDataAttachment('projectDetail') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template globalData={globalData} pageTitle='Project Details'
                                  content={ () => (
                                     <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                         <ProgressBar />
                                          <ProjectDetail authorized={this.state.authorized} role={this.state.role}/>
                                      </Suspense>
                                  )}/>
                        </Suspense>
                    </Route>

                    {
                        this.state.role === 'influencer' &&
                        <Route path='/mybids'>
                            { this.metaDataAttachment('mybids') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='My Bid Listing'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                 <InfluencerBidList/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        this.state.role === 'advertiser' &&
                        <Route path='/mybids'>
                            { this.metaDataAttachment('mybids') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Not Found 404'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                    <ProgressBar />
                                                    <PageNotFound role={this.state.role}/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {

                        this.state.role === 'advertiser' ?
                            <Route path='/publishPage/newProject/:draftProjectId'>
                                { this.metaDataAttachment('publishPage') }
                                <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                    <Template globalData={globalData} pageTitle='Publish Your Project'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                    <PublishProjectNewProject/>
                                              </Suspense>
                                          )}/>
                                </Suspense>
                            </Route> :
                            <Route path='/publishPage/newProject/:draftProjectId'>
                                { this.metaDataAttachment('publishPage') }
                                <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Login to Publish Your Project'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                   <Login/>
                                              </Suspense>
                                          )}/>
                                </Suspense>
                            </Route>
                    }
                    {

                        (this.state.role === 'advertiser' || this.state.role === 'influencer') ?
                            <Route
                                path='/successPageCredits/:successNewProjectId/:successNewProjectUserId/:successNewProjectPassword'>
                                { this.metaDataAttachment('successPageCredits') }
                                <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                    <Template globalData={globalData} pageTitle='Successful Credits'
                                              content={ () => (
                                                  <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                      <ProgressBar />
                                                        <SuccessPageNewProject/>
                                                  </Suspense>
                                              )}/>
                                </Suspense>
                            </Route> :
                            <Route path='/successPageCredits/newProject/:successNewProjectId'>
                                { this.metaDataAttachment('successPageCredits') }
                                <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <Template globalData={globalData} pageTitle='Successful Credits'
                                              content={ () => (
                                                  <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                      <ProgressBar />
                                                       <Login/>
                                                  </Suspense>
                                              )}/>
                                </Suspense>
                            </Route>
                    }
                    {
                        this.state.role === 'advertiser' &&
                        <Route path='/createProject'>
                            { this.metaDataAttachment('createProject') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Create A Project'
                                          content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <CreateProject/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }

                    {
                        this.state.role === 'advertiser' &&
                        <Route path='/myProjectLatest/:projectId'>
                            { this.metaDataAttachment('myProjectLatest') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='My Latest Project'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                  <AdvertiserProject/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        this.state.role === 'advertiser' &&
                        <Route path='/myProject'>
                            { this.metaDataAttachment('myProject') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='My Project Listing'
                                          content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                                <AdvertiserProject/>
                                          </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        this.state.role === 'influencer' &&
                        <Route path='/myProject'>
                            { this.metaDataAttachment('myProject') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Not Found 404'
                                  content={
                                      () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                              <PageNotFound role={this.state.role}/>
                                          </Suspense>
                                          )
                                        }/>
                            </Suspense>
                        </Route>
                    }
                    {
                        (this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path='/profile'>
                            { this.metaDataAttachment('profile') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='My Profile'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                  <Profile role={this.state.role}/>
                                              </Suspense>
                                          ) }/>
                            </Suspense>
                        </Route>
                    }
                    {
                        (this.state.role === 'advertiser') &&
                        <Route path={['/viewProfile/:username/:name/:projectid']}>
                            { this.metaDataAttachment('viewProfile') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                  <Template globalData={globalData} pageTitle='View A Profile'
                                        content={ () => (
                                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                <ProgressBar />
                                                <ViewProfile/>
                                            </Suspense>
                                        )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        (this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path='/transactionHistory'>
                            { this.metaDataAttachment('transactionHistory') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Credits Transaction History'
                                      content={ () => (
                                          <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                              <ProgressBar />
                                             <TransactionHistory role={this.state.role}/>
                                          </Suspense>
                                      )}/>
                            </Suspense>
                        </Route>
                    }
                    {(this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route
                            path='/newProjectTopUp/:totalProjectBudget&:topUpAmount&:projectTitle&:projectId&:calculation'>
                            { this.metaDataAttachment('newProjectTopUp') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Top Up Amount'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                   <TransactionHistory role={this.state.role}/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        (this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path='/notification'>
                            { this.metaDataAttachment('notification') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template globalData={globalData} pageTitle='Notification Listing'
                                          content={ () => (
                                              <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                                  <ProgressBar />
                                                  <NotificationList role={this.state.role}/>
                                              </Suspense>
                                          )}/>
                            </Suspense>
                        </Route>
                    }
                    {
                        (this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path={['/workspace/:id/:projectid']}>
                            { this.metaDataAttachment('workspace') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template
                                    globalData={globalData} pageTitle='Workspace'
                                    content={ () => (
                                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                            <ProgressBar />
                                           <Workspace role={this.state.role}/>
                                        </Suspense>
                                    )}
                                />
                            </Suspense>
                        </Route>
                    }

                    {
                        (this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path={['/disputeWorkspace/:id/:projectid']}>
                            { this.metaDataAttachment('disputeWorkspace') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template
                                    globalData={globalData} pageTitle='Dispute Workspace'
                                    content={ () => (
                                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                        <DisputeWorkSpace role={this.state.role}/>
                                        </Suspense>
                                    )}
                                />
                            </Suspense>
                        </Route>
                    }
                    {(this.state.role === 'influencer' || this.state.role === 'advertiser') &&
                        <Route path={'/dashboard'}>
                            { this.metaDataAttachment('dashboard') }
                            <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                <Template
                                    globalData={globalData} pageTitle='Dashboard'
                                    content={ () => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                         <ProgressBar />
                                          <MainPage role={this.state.role} authorized={this.state.authorized}/>
                                    </Suspense>
                                    )
                                }
                                />
                            </Suspense>
                        </Route>
                    }
                    <Route path={'/SEO'}>
                        { this.metaDataAttachment('SEO') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData} pageTitle='SEO MetaData'
                                content={() => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                        <Seo/>
                                    </Suspense>
                                )}
                            />
                        </Suspense>
                    </Route>
                    <Route path={'/'}>
                        { this.metaDataAttachment('') }
                        <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                            <Template
                                globalData={globalData}
                                pageTitle='Homepage'
                                content={() => (
                                    <Suspense fallback={<div className='suspenseDiv'>{this.lazyload()}</div>}>
                                        <ProgressBar />
                                         <MainPage />
                                    </Suspense>
                                )}
                            />
                        </Suspense>
                    </Route>

                </Switch>
            </Router>
             }
            </>
        );
    }

}

//
export const homepageUrl = "https://www.kolspire.com/";
export const apiUrl = 'https://publicapi.kolspire.com/index.php/api/';

// export const homepageUrl = "http://localhost:3000/";
// export const apiUrl = 'http://localhost:8000/api/';
export const imgUrl = apiUrl + 'images/';
export const projectImgUrl = apiUrl + 'project/';
export const workspaceFileUrl = apiUrl + 'workspaceFile/';

export function savePrevPage() {
    localStorage.setItem('savePrevPage', window.location.href);
}

export function getPrevPage() {
    return window.localStorage.getItem('savePrevPage');
}

export function deletesPrevPage() {
    localStorage.removeItem('savePrevPage');
}
export function sessionOverRedirect() {
    if(window.localStorage.getItem('jwt') === null) {

          if    (
                window.location.href.match(homepageUrl + 'influencer') ||
                window.location.href.match(homepageUrl + 'mybids') ||
                window.location.href.match(homepageUrl + 'projectDetail') ||
                window.location.href.match(homepageUrl + 'publishPage') ||
                window.location.href.match(homepageUrl + 'successPageCredits') ||
                window.location.href.match(homepageUrl + 'createProject') ||
                window.location.href.match(homepageUrl + 'myProject') ||
                window.location.href.match(homepageUrl + 'profile') ||
                window.location.href.match(homepageUrl + 'transactionHistory') ||
                window.location.href.match(homepageUrl + 'newProjectTopUp') ||
                window.location.href.match(homepageUrl + 'notification') ||
                window.location.href.match(homepageUrl + 'workspace') ||
                window.location.href.match(homepageUrl + 'disputeWorkspace') ||
                window.location.href.match(homepageUrl + 'dashboard')) {
                savePrevPage();
            }
        window.location.href= '/login';
    }
}


export function _fetch(obj){

    let jwt =  window.localStorage.getItem('jwt');

    // let countOfNulls = 0;
    // while(counter < 10) {
    //    if(window.localStorage.getItem('saveSession' + counter) !== null) {
    //        sessionStorage.setItem('jwt' + counter ,window.localStorage.getItem('saveSession' + counter));
    //        localStorage.removeItem('saveSession' + counter);
    //        localStorage.setItem('saveSession' + counter ,window.sessionStorage.getItem('jwt' + counter));
    //        oneSessionExist = true;
    //    }
    //     counter ++;
    // }

    let url = apiUrl + (obj.api || '');

    let headers = {
        //'Authorization' : 'Bearer ' + jwt,
        // 'Accept' : 'application/json',
        // 'Content-Type' : 'application/json',
        //'Content-Type' : 'multipart/form-data',
    };

    if(obj.headers)
        headers = obj.headers

    if(jwt)
         headers.Authorization = 'Bearer ' + jwt

    let init = {
        url: url,
        mode: 'cors',
        crossDomain: true,
        credentials: 'include',
        //withCredentials: true,
        method: 'post',
        headers: headers,
    }

    if(obj.data !== undefined){
        init.body = obj.data
        //init.body = JSON.stringify(obj.data.values)
        //let formData = new FormData();
        //console.log(init)
        //console.log(JSON.stringify(obj.data.values))
    }
    let pauseEventListener = '';
    let refreshOnce = '';
    let refreshToken = () => {

        let formData = new FormData();
        if(window.localStorage.getItem('refreshMe') !== null)
        {
            formData.append('refreshMe',window.localStorage.getItem('refreshMe'));
            formData.append('minutesTokenExpire',window.localStorage.getItem('minutesTokenExpire'));
            _fetch({
                api: 'beforelogin/refreshToken',
                data: formData
            }).then(data => {
                if (data.token) {
                    localStorage.setItem('minutesTokenExpire', data.expires_in)
                    localStorage.setItem('refreshMe', data.timeNow)
                    localStorage.setItem('jwt', data.token)

                }
            })
        }
    }



    let checkSession = () => {
        _fetch({
            api : 'user/checkSession',
        }).then(data => {
            let logoutError=["Session Expired","Unauthorized"];
            if (logoutError.includes(data.msg)) {
                refreshOnce = true;
                pauseEventListener = true;
                return false
            }else if(data.status === 'success'){

                refreshOnce = false;
                pauseEventListener = false;
            }
        })


    }


    return fetch(url, init)
        .then(res => res.json())
        .then(res => {

         if(obj.api === 'beforelogin/refreshToken') {
             return res
         } else if (obj.api === 'user/checkSession') {
                 let logoutError = ["Session Expired", "Unauthorized"];
                 if (logoutError.includes(res.msg)) {
                     localStorage.removeItem('minutesTokenExpire');
                     localStorage.removeItem('refreshMe');

                         if(window.location.href !== homepageUrl + 'login' &&
                             window.location.href !== 'kolspire.com/login' &&
                             window.location.href !== 'localhost:3000/login' &&
                             !window.location.href.toString().match(/login/i)) {


                             if (window.localStorage.getItem('jwt') !== null) {
                                 localStorage.removeItem('jwt');
                                 // logout on another tab or expired session but havent refresh the page( Logout button not click )
                                 // if(getPrevPage() !== null && getPrevPage() !== undefined ) {
                                 //     if(window.location.href !== homepageUrl + 'login' &&
                                 //         window.location.href !== 'kolspire.com/login' &&
                                 //         window.location.href !== 'localhost:3000/login' &&
                                 //         !window.location.href.toString().match(/login/i)) {
                                 //         pauseEventListener = true;
                                 //         window.location.href='/login';
                                 //     }
                                 // }
                                 if (window.location.href !== homepageUrl + 'login' &&
                                     window.location.href !== 'kolspire.com/login' &&
                                     window.location.href !== 'localhost:3000/login') {
                                     if (
                                         // window.location.href.match(homepageUrl + 'unsubscribe') ||
                                         window.location.href.match(homepageUrl + 'projectDetail') ||
                                         window.location.href.match(homepageUrl + 'about') ||
                                         window.location.href.match(homepageUrl + 'privacy') ||
                                         window.location.href.match(homepageUrl + 'terms') ||
                                         window.location.href.match(homepageUrl + 'feesAndCharges') ||
                                         window.location.href.match(homepageUrl + 'privacy') ||
                                         window.location.href.match(homepageUrl + 'contactUs') ||
                                         // window.location.href.match(homepageUrl + 'requestResetPassword') ||
                                         // window.location.href.match(homepageUrl + 'resetPassword') ||
                                         // window.location.href.match(homepageUrl + 'beforeConfirmation') ||
                                         // window.location.href.match(homepageUrl + 'afterConfirmation') ||
                                         window.location.href.match(homepageUrl + 'feesAndCharges') ||
                                         window.location.href.match(homepageUrl + 'faq')
                                         // window.location.href.match(homepageUrl + 'registerInfluencer') ||
                                         // window.location.href.match(homepageUrl + 'registerAdvertiser')

                                     ) {
                                         pauseEventListener = true;
                                         window.location.reload()
                                     } else if (
                                         window.location.href.match(homepageUrl + 'workspaceList') ||
                                         window.location.href.match(homepageUrl + 'mybids') ||
                                         window.location.href.match(homepageUrl + 'publishPage') ||
                                         window.location.href.match(homepageUrl + 'successPageCredits') ||
                                         window.location.href.match(homepageUrl + 'createProject') ||
                                         window.location.href.match(homepageUrl + 'myProject') ||
                                         window.location.href.match(homepageUrl + 'profile') ||
                                         window.location.href.match(homepageUrl + 'transactionHistory') ||
                                         window.location.href.match(homepageUrl + 'newProjectTopUp') ||
                                         window.location.href.match(homepageUrl + 'notification') ||
                                         window.location.href.match(homepageUrl + 'workspace') ||
                                         window.location.href.match(homepageUrl + 'disputeWorkspace') ||
                                         window.location.href.match(homepageUrl + 'dashboard')
                                     ) {
                                         savePrevPage();
                                         pauseEventListener = true;
                                         window.location.href = '/login';
                                     }
                                 }
                             } else {
                                 // ( Logout button clicked or no front end JWT Token found)
                                 if (window.location.href.match(homepageUrl + 'adminAccessWorkspace')) {

                                 } else if (window.location.href !== homepageUrl + 'login' &&
                                     window.location.href !== 'kolspire.com/login' &&
                                     window.location.href !== 'localhost:3000/login') {
                                     if (
                                         // window.location.href.match(homepageUrl + 'unsubscribe') ||

                                         window.location.href.match(homepageUrl + 'projectDetail') ||
                                         window.location.href.match(homepageUrl + 'about') ||
                                         window.location.href.match(homepageUrl + 'privacy') ||
                                         window.location.href.match(homepageUrl + 'terms') ||
                                         window.location.href.match(homepageUrl + 'feesAndCharges') ||
                                         window.location.href.match(homepageUrl + 'privacy') ||
                                         window.location.href.match(homepageUrl + 'contactUs') ||
                                         // window.location.href.match(homepageUrl + 'requestResetPassword') ||
                                         // window.location.href.match(homepageUrl + 'resetPassword') ||
                                         // window.location.href.match(homepageUrl + 'beforeConfirmation') ||
                                         // window.location.href.match(homepageUrl + 'afterConfirmation') ||
                                         window.location.href.match(homepageUrl + 'feesAndCharges') ||
                                         window.location.href.match(homepageUrl + 'faq')
                                         // window.location.href.match(homepageUrl + 'registerInfluencer') ||
                                         // window.location.href.match(homepageUrl + 'registerAdvertiser')

                                     ) {
                                         if (refreshOnce === false) {
                                             pauseEventListener = true;
                                             refreshOnce = true;
                                             window.location.reload()
                                         }
                                     } else if (
                                         window.location.href.match(homepageUrl + 'workspaceList') ||
                                         window.location.href.match(homepageUrl + 'viewProfile') ||
                                         window.location.href.match(homepageUrl + 'mybids') ||
                                         window.location.href.match(homepageUrl + 'publishPage') ||
                                         window.location.href.match(homepageUrl + 'successPageCredits') ||
                                         window.location.href.match(homepageUrl + 'createProject') ||
                                         window.location.href.match(homepageUrl + 'myProject') ||
                                         window.location.href.match(homepageUrl + 'profile') ||
                                         window.location.href.match(homepageUrl + 'transactionHistory') ||
                                         window.location.href.match(homepageUrl + 'newProjectTopUp') ||
                                         window.location.href.match(homepageUrl + 'notification') ||
                                         window.location.href.match(homepageUrl + 'workspace') ||
                                         window.location.href.match(homepageUrl + 'disputeWorkspace') ||
                                         window.location.href.match(homepageUrl + 'dashboard')
                                     ) {
                                         savePrevPage();
                                         pauseEventListener = true;
                                         refreshOnce = true;
                                         window.location.href = '/login';
                                     }
                                 }
                             }
                         }
                   }
                 else {
                     if(obj.api !== 'beforelogin/logout') {
                         refreshToken();
                     }
                     // document.body.addEventListener('click', () => {
                     //     refreshToken();
                     // })
                     // document.body.addEventListener('keydown', () => {
                     //     refreshToken();
                     // })
                 }
             return res
             } else if (obj.api !== 'user/checkSession') {


             //any ajax call that isnt checking for the expired token or logout token will 'save previous Page'.

                 let logoutError = ["Session Expired"];
                 if (logoutError.includes(res.msg)) {
                         checkSession();
                     // window.localStorage.setItem('tempPath', window._history.location.pathname);
                 } else {
                 if( window.location.href.match(homepageUrl + 'viewProfile') ||
                     window.location.href.match(homepageUrl + 'influencer') ||
                     window.location.href.match(homepageUrl + 'mybids') ||
                     window.location.href.match(homepageUrl + 'publishPage') ||
                     window.location.href.match(homepageUrl + 'successPageCredits') ||
                     window.location.href.match(homepageUrl + 'createProject') ||
                     window.location.href.match(homepageUrl + 'myProject') ||
                     window.location.href.match(homepageUrl + 'profile') ||
                     window.location.href.match(homepageUrl + 'transactionHistory') ||
                     window.location.href.match(homepageUrl + 'newProjectTopUp') ||
                     window.location.href.match(homepageUrl + 'notification') ||
                     window.location.href.match(homepageUrl + 'workspace') ||
                     window.location.href.match(homepageUrl + 'disputeWorkspace') ||
                     window.location.href.match(homepageUrl + 'dashboard')
                 ) {
                     if (document.body.getAttribute('listener') !== 'true') {
                         document.body.addEventListener('click', (e) => {
                             if (pauseEventListener === '') {
                                 if (e.target.tagName === 'BUTTON') {
                                     sessionOverRedirect();
                                 }
                                 if (obj.api !== 'beforelogin/logout') {
                                     if (window.localStorage.getItem('jwt') === null) {

                                     } else {
                                         checkSession();
                                     }
                                 }
                             }
                         })

                             document.body.addEventListener('keydown', () => {
                                 if (pauseEventListener === '') {
                                     if (obj.api !== 'beforelogin/logout') {
                                         checkSession();
                                     }
                                 }
                             })
                             document.body.setAttribute('listener', 'true');
                           }
                       }

                 }
             return res

         }


        }).catch(error => {
            console.log(error)
        })

}

import React, {Component, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Image, Drawer, Dropdown, Menu, message} from "antd";
import logo from "../../Images/KOLSpire-sitelogo-1024x286.webp";
import {_fetch, apiUrl, imgUrl} from "../../Common/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle,faBell,faRedo,faDollarSign,faNewspaper, faUser, faSignOutAlt,faPersonBooth, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { savePrevPage, deletesPrevPage, getPrevPage } from "../../Common/common";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { homepageUrl} from "../../Common/common";
// window.addEventListener("beforeunload", function(evt) {
//
//     // Cancel the event (if necessary)
//     evt.preventDefault();
//     // localStorage.removeItem('jwt')
//     // Google Chrome requires returnValue to be set
//     evt.returnValue = '';
//
//     return null;
// });

const navBarLink = [
    {
        'link' : 'project',
        'label' : 'PROJECTS LISTING'
    },
    {
        'link' : 'influencer',
        'label' : 'INFLUENCERS LISTING'
    },
    {
        'link' : 'faq',
        'label' : 'FAQ'
    }
];

const advertiserLink = [
    {
        'link' : 'notification',
        'label' : 'NOTIFICATION'
    },
    {
        'link' : 'transactionHistory',
        'label' : 'MY TRANSACTION HISTORY'
    },
    {
        'link' : 'createProject',
        'label' : 'CREATE A PROJECT'
    },
    {
        'link' : 'myProject',
        'label' : 'MY PROJECTS LISTING'
    },
    {
        'link' : 'workspaceList',
        'label' : 'MY WORKSPACE LISTING'
    }
]

const influencerLink = [
    {
        'link' : 'notification',
        'label' : 'NOTIFICATION'
    },
    {
        'link' : 'transactionHistory',
        'label' : 'MY TRANSACTION HISTORY'
    },
    {
        'link' : 'mybids',
        'label' : 'MY BIDS LISTING'
    }
]

const afterLoginLink = [
    {
        'link' : 'profile',
        'label' : 'MY PROFILE'
    }
]
window.onblur = function() {
    return "the user just switched tabs";
};

const bidprojectButton =
    <a href={'/project'} >
        <button className="font-bold w-full block text-sm py-4 px-2 leading-none border rounded text-white bg-blue-700 border-blue-700 hover:border-transparent hover:text-[hsl(208,79%,51%)] hover:border-blue-700 hover:bg-white mr-4 lg:mt-0">BID PROJECT NOW</button>
    </a>

export default class NavBar extends Component{

    state = {
        authorized: false,
        toggle: false,
        toggleLeft: false,
        username: '',
        notificationList: [],
        haveNewNotify:false,
        loading: true,
        notlogin: true,
        balanceWithoutPrefix: '',
        refreshingCredits:false,
        nameOfUser:'',
        drawerSizeRight: 0,
        pictureError: false,
    }

    // initActiveChat = (username) => {
    //
    //     let formData = new FormData;
    //     formData.append('username', username);
    //     _fetch({
    //         api: 'onlineStatus/updateChatOnline',
    //         data: formData
    //     }).then(data => {
    //
    //         this.setState({
    //             selfActive: 'online',
    //         })
    //     })
    // }

    componentDidMount() {

        this.checkSession()

        if(this.props.authorized === true) {
            // window.addEventListener('beforeunload', (e) => {
            //     e.preventDefault()
            //     return (e.returnValue = 'Are you sure you want to close?')
            // })
            this.initBal() }
        // this.notification = setInterval( () => this.getLatestNotification(), 10000)

        // this.initActiveChat(this.props.username);
        // setInterval(() => this.initActiveChat(this.props.username), 10000);
            if (localStorage.getItem("successfullySignIn") === null) {
                if (this.props.username) {
                    document.getElementById('loginCheck').classList.remove('hidden')
                    document.getElementById('contentMessage').innerHTML = "Loading...."
                    if (this.state.loading === false) {
                        if (this.state.authorized === true) {
                            document.getElementById('loginCheck').classList.add('hidden')
                            document.getElementById('contentMessage').innerHTML = "You have signed in Successfully"
                        }
                    }
                }
            } else {
                document.getElementById('loginCheck').classList.remove('hidden');
                localStorage.removeItem('successfullySignIn');
            }
        let timer = setInterval( () => {
            if(this.state.authorized == true) {
                document.getElementById('loginCheck').classList.add('hidden')
                clearInterval(timer)
            }
        },2000)

    }

    checkSession = () => {
        _fetch({
            api : 'user/checkSession',
        }).then(data => {
            let logoutError=["Session Expired","Unauthorized"];
            if (logoutError.includes(data.msg)) {

                return false
            }else if(data.status === 'success'){
                this.setState({
                    authorized: true,
                    role: data.role,
                    loading: false
                })

                this.getProfile()
                this.getLatestNotification()

            }
        })
    }

    toggleMenu = () => {
        this.initBal();
        this.setState({
            toggle: !this.state.toggle
        })

        if(document.body.scrollWidth > 1280){
            this.setState({
                drawerSizeRight: 320
            })
        } else {
            this.setState({
                drawerSizeRight: 280
            })
        }

    }

    toggleMenuLeft = () => {
        this.initBal();
        this.setState({
            toggleLeft: !this.state.toggleLeft
        })
    }

    getProfile = () => {
        _fetch({
            api : 'user/initProfile',
        }).then(data => {
            this.setState({
                nameOfUser: data.userDetail.fullname,
                username: data.userDetail.username,
                role: data.userDetail.role
            })
        })
    }

    getLatestNotification = (bidid) => {
        let formData = new FormData();
        formData.append('bidId', bidid);
        _fetch({
            api : 'notification/latestNotification',
            data : formData
        }).then(data => {
            this.setState({
                haveNewNotify:data.haveNewNotify,
                notificationList: data.data
            })
        })
    }

    initBal = () => {
        this.setState({
            refreshingCredits: true
        })
        _fetch({
            api: 'user/initBalance',
        }).then(data => {
            console.log(data)
            this.setState({
                balanceWithoutPrefix: data.balance.trim().replace(/RM/g,'').replace(/\.00/g,'').replace(/,/g,'').replace(/\s/g,'')
            })
            this.setState({
                refreshingCredits: false
            })
        })
    }
    handleLogout = () => {
        _fetch({
            api : 'beforelogin/logout',
        }).then(data => {
            localStorage.removeItem('minutesTokenExpire');
            localStorage.removeItem('refreshMe');
            localStorage.removeItem('jwt');

            savePrevPage();
            window.location.href = '/login';

        })
    }
    // <div className="flex">
    //   <div className="ml-[180px] text-xl">NOTIFICATION</div>
    //   <button className="border-0 bg-transparent -mt-[20px] ml-[120px] p-3 text-xl">x</button>
    // </div>
    renderNotification = () => {
        return(

            <Menu>

                {
                    this.state.notificationList.length > 0 && this.state.notificationList.map( (val,key) => (
                     <div key={ "notification" + key }>
                        <Menu.Item className="max-w-lg" >
                                <a id ="linkTo" href = { val.fullLink }>
                                    <div className="p-1 w-full flex items-center">
                                        <div
                                            className="overflow-hidden flex max-w-[60px] max-h-[60px] whitespace-normal block float-left border-solid border-[1px] rounded-full border-black justify-center items-center">
                                            <Image
                                                className="object-contain flex min-w-[60px] min-h-[60px] rounded-full"
                                                src={ apiUrl + val.avatar }/>
                                        </div>
                                        <div className="p-2 w-full whitespace-normal text-justify float-right text-lg">{val.content}</div>
                                    </div>
                                    <div className="w-full whitespace-normal block text-center">{val.date} </div>
                                </a>
                        </Menu.Item>
                        <Menu.Divider />
                     </div>
                    ))
                }
                <Menu.Item key={ "notificationPage" } className="max-w-lg text-center ">
                    <a href={"/notification"}>
                        <span className={'font-bold'}>See all Notification</span>
                    </a>
                </Menu.Item>
            </Menu>
        )
    }

    notifyIcon = () => {
        return(
            <>
                <FontAwesomeIcon icon={ faBell } />
                {
                    this.state.haveNewNotify && <span className="text-[40px] fa-layers-counter">NEW</span>
                }
            </>
        )
    }
    refreshCredits = () => {
        return(
            <>
                <span>{ this.state.refreshingCredits === false && <FontAwesomeIcon icon={ faRedo } /> }</span>
                {this.state.refreshingCredits === true &&
                <span className="animate-ping">
                   <FontAwesomeIcon icon={ faRedo } />
                </span>
                }
            </>
        )
    }

    // postProjectButton = () => {
    //    return (
    //        this.props.username &&
    //         this.state.loading === false &&
    //         this.state.authorized === true &&
    //         <a href={'/createProject'} >
    //             <button className="text-content text-black block w-full text-content hover:bg-gray-100 bg-whites">POST A NEW PROJECT</button>
    //         </a>
    //   )
    // }

    successfulLoginBanner = () => {
        return (
         <div id="loginCheck" className="hidden">
            <div className="flex justify-center top-0 h-[100px] lg:h-[120px] xl:h-[100px] fixed bg-blue-300  w-full text-[20px] z-[100] opacity-[90%]">
                <div id="contentMessage" className="self-center h-[30px]">You have signed in Successfully</div>
            </div>
            <div className="top-[100px] lg:top-[120px] xl:top-[100px] h-[10000px] fixed bg-gray-300  w-full text-[20px] z-[100] opacity-75">

            </div>
         </div>
        )
    }

    render() {
        return(
            <div className="relative z-[1005]">
                <div>{ this.successfulLoginBanner() }</div>
                <nav className="h-[98px] NavBar flex items-center justify-between bg-white xl:pl-32 lg:px-16 p-6 fixed top-0 left-0 right-0 z-10">
                    <div className="flex grow xl:grow-0 items-center">
                        <button
                            className="mr-2 grow-0 flex xl:hidden items-center px-3 py-2 border rounded bg-blue-700 border-blue-700 hover:bg-gray-400 text-white"
                            onClick={this.toggleMenuLeft}
                        >
                            <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                            </svg>
                        </button>



                        <div className="xl:grow-0 grow justify-center flex items-center xl:justify-start text-black" key='navBarLogo'>
                            <a className="block" href={'/'} >
                                <img className="w-40" src={logo} />
                            </a>
                        </div>
                    </div>
                    <div className="xl:grow block" key='navBarMobile'>
                        <div className={'flex items-center sm:mt-2'}>
                            <div className= {"w-full  justify-center flex-grow lg:flex lg:items-center lg:w-auto z-10 hidden"} key='navBarLink'>
                                <div className="md:flex hidden text-main-menu lg:flex-grow">
                                    {
                                        navBarLink.map((value, key) => (
                                            <a href={'/' + value.link} key={"navbarMOBILE" + key}>
                                                <button className="mx-4 text-content-big font-bold text-black hidden mt-4 xl:inline-block lg:mt-0 mr-4 hover:text-[hsl(208,79%,51%)]">
                                                    {value.label}
                                                </button>
                                            </a>
                                        ))
                                    }

                                </div>
                            </div>
                            { this.props.authorized &&
                                <div className="sm:flex hidden flex-wrap notification_container items-center relative z-[1006]">
                                    <Dropdown overlayClassName="max-w-lg" placement="bottom" overlay={
                                        this.renderNotification()
                                    } trigger={['click']}>

                                        <button
                                            onClick={() => {
                                                this.setState({haveNewNotify:false})
                                                this.getLatestNotification();
                                                this.initBal()
                                            }}
                                            className="flex flex-row items-center w-full px-1 py-1 mt-2 text-sm text-left bg-transparent rounded-full md:w-auto md:mt-0 md:ml-2 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                        >
                                                <span className="fa-layers fa-fw text-4xl">
                                                    {this.notifyIcon()}
                                                </span>
                                        </button>
                                    </Dropdown>
                                </div>
                            }
                            {
                                <button
                                    className="min-w-[40px] min-h-[40px] mobile notificationBtn flex flex-row items-center w-full mt-2 text-left p-1 bg-blue-700 rounded-full max-w-[280px] w-auto md:mt-0 md:ml-2 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                    onClick={ () => { if(this.props.authorized === true)  { this.toggleMenu() } } }>
                                    <>{
                                            <div className="flex flex-nowrap items-center">
                                                {this.props.authorized === true ?
                                                    <div className="text-content-big min-w-[40px] min-h-[40px]">
                                                        {
                                                            (this.props.username && this.state.pictureError === false) && <img src={imgUrl + this.props.username + 'noShowImg'}
                                                                                        className="w-10 h-10 rounded-full"
                                                                                        alt ="" onError = { (event) => {

                                                                                             this.setState({
                                                                                                 pictureError: true
                                                                                             })
                                                                                                                event.onerror = null;
                                                                                                           }} />
                                                        }
                                                        {   (this.props.username && this.state.pictureError === true) &&

                                                            <div className="flex">
                                                                <div className="flex items-center">
                                                                    <div
                                                                        className="text-content-big min-w-[40px] min-h-[40px] flex justify-center items-center">
                                                                        <div className="fa-solid text-2xl text-center">
                                                                            <FontAwesomeIcon icon={faUser} className="w-auto text-white"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div> :
                                                    <div className="flex">
                                                        <a href={'/login'} className="flex items-center">
                                                            <div
                                                                className="text-content-big min-w-[40px] min-h-[40px] flex justify-center items-center">
                                                                <div className="fa-solid text-2xl text-center">
                                                                    <FontAwesomeIcon icon={faUser} className="w-auto text-white"/>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {/*{this.props.authorized === true &&*/}
                                        {/*    <button id="displayFullUsernamePageView"*/}
                                        {/*                className='mr-4 text-sm sm:flex font-bold hidden mx-2 w-auto justify-center'*/}
                                        {/*            onMouseEnter={(e) => {*/}
                                        
                                        {/*                let namePerSpaces = this.state.nameOfUser.toString().split(' ');*/}
                                        {/*                let newString = [];*/}
                                        {/*                namePerSpaces.map((value, index) => {*/}
                                        {/*                    newString.push(value.slice(0, 15));*/}
                                        {/*                    newString.push(value.slice(15));*/}
                                        {/*                })*/}
                                        {/*                if (e.target.id === "displayFullUsernamePageView") {*/}
                                        {/*                    document.getElementById(e.target.id).style.maxWidth = '230px';*/}
                                        {/*                    document.getElementById(e.target.id).style.width = 'auto';*/}
                                        {/*                    document.getElementById(e.target.id).innerHTML = newString.join(' ');*/}
                                        {/*                }*/}
                                        {/*            }} onMouseLeave={(e) => {*/}
                                        
                                        {/*        if (e.target.id === "displayFullUsernamePageView") {*/}
                                        {/*            // document.getElementById(e.target.id).style.marginLeft = "0px";*/}
                                        {/*            document.getElementById(e.target.id).style.maxWidth = '230px';*/}
                                        {/*            if (this.state.nameOfUser.toString().length > 15) {*/}
                                        {/*                document.getElementById(e.target.id).innerHTML = this.state.nameOfUser.toString().slice(0, 15) + '...';*/}
                                        {/*            } else {*/}
                                        {/*                document.getElementById(e.target.id).innerHTML = this.state.nameOfUser.toString();*/}
                                        {/*            }*/}
                                        {/*        }*/}
                                        
                                        {/*    }}>{this.state.nameOfUser.toString().slice(0, 15)}{this.state.nameOfUser.toString().length > 15 && "..."}</button>*/}
                                        {/*}*/}
                                    </>
                                </button>
                            }
                          </div>
                    </div>
                </nav>
                <Drawer placement="right" onClick={ () => this.toggleMenu
                } visible={this.state.toggle} width={ this.state.drawerSizeRight } onClose={() => this.setState({ toggle: false}) }>
                    <div className="flex flex-col space-y-8">
                        { this.props.authorized === false &&
                            <>
                                <div className="mt-10">
                                    <hr />
                                    {
                                        navBarLink.map((value, key) => (
                                            <div key={"navbar" + key}>
                                                {
                                                    this.props.authorized === true && value.link !== 'faq' &&
                                                    <a href={'/' + value.link}>
                                                        <button
                                                            className="text-content mx-4 text-black block mt-4 lg:inline-block lg:mt-0 mr-4 hover:text-[hsl(208,79%,51%)]"
                                                            >{value.label}
                                                        </button>
                                                    </a>
                                                }

                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        {  this.props.authorized === true &&
                            <>

                        <div className='py-2'>
                            <hr />
                        </div>

                            <div key={'drawer5'} className="flex flex-col">
                                <div>
                                    {   <p>
                                        <button id="displayFullUsernamePageViewMobile" className='text-xl mx-2 font-bold flex px-2 w-auto justify-center'
                                                onMouseEnter = { (e) => {

                                                let namePerSpaces = this.state.nameOfUser.toString().split(' ');
                                                let newString = [];
                                                namePerSpaces.map ( (value,index) => {
                                                    newString.push(value.slice(0,15));
                                                    newString.push(value.slice(15));
                                                })
                                                if(e.target.id === "displayFullUsernamePageViewMobile") {
                                                    document.getElementById(e.target.id).style.maxWidth = '230px';
                                                    document.getElementById(e.target.id).style.width = 'auto';
                                                    document.getElementById(e.target.id).innerHTML = newString.join(' ');
                                                }
                                            }} onMouseLeave = { (e) => {

                                                if(e.target.id === "displayFullUsernamePageViewMobile") {
                                                    // document.getElementById(e.target.id).style.marginLeft = "0px";
                                                    document.getElementById(e.target.id).style.maxWidth = '230px';
                                                    if(this.state.nameOfUser.toString().length > 15) {
                                                        document.getElementById(e.target.id).innerHTML = this.state.nameOfUser.toString().slice(0, 15) + '...';
                                                    } else {
                                                        document.getElementById(e.target.id).innerHTML = this.state.nameOfUser.toString();
                                                    }
                                                }

                                            }}>{this.state.nameOfUser.toString().slice(0,15)}{this.state.nameOfUser.toString().length > 15 && "..."}</button>
                                        </p>
                                    }
                                    {
                                        this.props.role === 'advertiser' &&
                                        advertiserLink.map((value,key) =>
                                            <p key={'navMbAdv' + key}>
                                                {value.link !== 'transactionHistory' ?
                                                    <>
                                                        {
                                                            value.link === 'createProject' &&
                                                            <a className="flex text-black items-center" href={'/' + value.link}>
                                                                 <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faCreditCard } />
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>
                                                        }
                                                        {
                                                            value.link === 'workspaceList' &&
                                                            <a className="flex text-black items-center" href={'/' + value.link}>
                                                                 <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faPersonBooth } />
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>
                                                        }
                                                        {
                                                            value.link === 'myProject' &&
                                                            <a className="flex text-black items-center" href={'/' + value.link}>
                                                                 <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faNewspaper } />
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>
                                                        }
                                                        {
                                                            value.link === 'notification' &&
                                                            <a className="sm:hidden flex text-black items-center" href={'/' + value.link}>
                                                                <div className="fa-layers fa-fw text-2xl mr-2">
                                                                      <FontAwesomeIcon icon={ faBell } />
                                                                    {
                                                                        this.state.haveNewNotify && <span className="text-[30px] fa-layers-counter">NEW</span>
                                                                    }
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>

                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <span
                                                           className="block w-full leading-none hover:text-[hsl(208,79%,51%)] lg:mt-0">
                                                          <div> <a className="text-black flex items-center" href={"/" + value.link}>
                                                                <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faDollarSign } />
                                                                </div>
                                                                <div className="text-content mr-2">{'CREDITS:'}</div>
                                                                <div className="text-content hover:break-words text-ellipsis hover:overflow-visible overflow-hidden whitespace-nowrap hover:whitespace-pre-wrap text-center">
                                                                    {'RM ' + this.state.balanceWithoutPrefix}</div>
                                                                </a>
                                                               </div>
                                                        </span>
                                                    </>
                                                }
                                            </p>
                                        )
                                    }
                                    {
                                        this.props.role === 'influencer' &&
                                        influencerLink.map((value,key) =>
                                            <p key={'navMbInf' + key}>
                                                {value.link !== 'transactionHistory' ?
                                                    <>
                                                        {/*{*/}
                                                        {/*    value.link === 'notification' &&*/}
                                                        {/*    <a className="flex text-black items-center" href={'/' + value.link}>*/}
                                                        {/*        <div className="fa-layers fa-fw text-2xl mr-2">*/}
                                                        {/*            <FontAwesomeIcon icon={ faBell } />*/}
                                                        {/*            {*/}
                                                        {/*                this.state.haveNewNotify && <span className="text-[30px] fa-layers-counter">NEW</span>*/}
                                                        {/*            }*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>*/}
                                                        {/*    </a>*/}
                                                        {/*}*/}
                                                        {
                                                            value.link === 'notification' &&
                                                            <a className="sm:hidden flex text-black items-center" href={'/' + value.link}>
                                                                <div className="fa-layers fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faBell } />
                                                                    {
                                                                        this.state.haveNewNotify && <span className="text-[30px] fa-layers-counter">NEW</span>
                                                                    }
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>

                                                        }
                                                        {
                                                            value.link === 'mybids' &&
                                                            <a className="flex text-black items-center" href={'/' + value.link}>
                                                                <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faCreditCard } />
                                                                </div>
                                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                            </a>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <span
                                                            className="block w-full leading-none hover:text-[hsl(208,79%,51%)] lg:mt-0">
                                                          <div> <a className="text-black flex items-center" href={"/" + value.link}>
                                                                <div className="fa-fw text-2xl mr-2">
                                                                    <FontAwesomeIcon icon={ faDollarSign } />
                                                                </div>
                                                                <div className="text-content mr-2">{'CREDITS:'}</div>
                                                                <div className="text-content hover:break-words text-ellipsis hover:overflow-visible overflow-hidden whitespace-nowrap hover:whitespace-pre-wrap text-center">
                                                                    {'RM ' + this.state.balanceWithoutPrefix}</div>
                                                                </a>
                                                               </div>
                                                        </span>
                                                    </>
                                                }
                                            </p>
                                        )
                                    }
                                    {
                                        afterLoginLink.map((value,key) =>
                                            <p key = {"afterLogin" + key }>
                                            {
                                                value.link === 'profile' &&
                                                <a className="flex text-black items-center" href={'/' + value.link}>
                                                    <div className="fa-layers fa-fw text-2xl mr-2">
                                                        <FontAwesomeIcon icon={ faUser } />
                                                    </div>
                                                    <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">{value.label}</div>
                                                </a>
                                            }
                                            </p>
                                        )
                                    }
                                    {
                                        <p>
                                            <button id="btnLogout" onClick={this.handleLogout} className="flex text-black items-center" href={"/login"}>
                                                <div className="fa-layers fa-fw text-2xl mr-2">
                                                    <FontAwesomeIcon icon={ faSignOutAlt } />
                                                </div>
                                                <div className="text-content block lg:inline-block lg:mt-0 text-black hover:text-[hsl(208,79%,51%)]">LOGOUT</div>
                                            </button>
                                        </p>
                                    }
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </Drawer>

                <Drawer className="flex xl:hidden" placement="left" onClick={ () => this.toggleMenuLeft() } visible={this.state.toggleLeft} width={280} onClose={() => this.setState({ toggleLeft: false}) }>
                    <div className="flex xl:hidden flex-col space-y-8">
                        <>
                            <div className="mt-10">
                                {
                                    navBarLink.map((value, key) => (
                                        <div key={"navbarLEFT" + (key)}>
                                            {
                                                <a href={'/' + value.link}>
                                                    <button
                                                        className="w-full text-content text-black block mt-4 lg:inline-block lg:mt-0 mr-4 hover:text-[hsl(208,79%,51%)]"
                                                        >
                                                        <div
                                                            className="w-full text-content-big min-w-[40px] min-h-[40px] flex items-center">
                                                            <div className="fa-solid text-xl text-center mr-2">
                                                                <FontAwesomeIcon icon={faDotCircle} className="w-auto text-black bg-black border-2 rounded-full"/>
                                                            </div>
                                                            <div>{value.label}</div>
                                                        </div>
                                                    </button>
                                                </a>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    </div>
                </Drawer>




            </div>
        )
    }
}

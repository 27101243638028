import React, {Component,lazy,Suspense} from 'react';
import {BackTop } from 'antd'
import NavBar from "../NavBar";
import versionUpdateRender from "../VersionControls";
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {_fetch, homepageUrl} from '../../Common/common.js';
import { Helmet } from 'react-helmet';
const Footer = lazy( () => import("../Footer"));

export default class Template extends Component {

    constructor(props) {
        super(props);
        console.log(props)
        let extractHistory = [];
    }

    state = {
        history: [],
        linksSaved: [],
        metaDataNotMatch: []
    }
    activateGlobalClick = (value) => {
        // let obj = Object.keys(this.navigationPanel[value]);
        // Object.keys(obj).map( (keys) => {
        //     alert(obj[keys][0]);
        // });
        let formData = new FormData();

        formData.append('linkNow', value);

        let fullLink = window.location.href + 'ABC---ABC';
        if (fullLink.match('\/ABC---ABC')) {
            fullLink = fullLink.replace('\/ABC---ABC', '')
        } else {
            fullLink = fullLink.replace('ABC---ABC', '')
        }

        formData.append('fullLink', fullLink);
        if (sessionStorage.getItem("kolspireNavHistory") !== null) {
            formData.append('base', sessionStorage.getItem("kolspireNavHistory"));
        }


        _fetch({
            api: 'homepage/navHistory',
            data: formData
        }).then(data => {
            if (data.status === 'success') {
                this.setState({
                    history: data.data[fullLink][0]
                })
                if (data.data['base'][0] !== undefined) {
                    sessionStorage.setItem("kolspireNavHistory", data.data['base'][0]);
                } else {
                    sessionStorage.setItem("kolspireNavHistory", null);
                }
            } else if (data.status === 'error') {
                this.setState({
                    history: []
                })
            } else {
                this.setState({
                    history: []
                })
            }

        })

    }

    componentDidMount() {
        this.activateGlobalClick(window.location.href.toString().replaceAll(homepageUrl, '').split('/')[0])
    }



    render() {
        return (

            <div className='relative bg-gray-100 w-full'>

                <BackTop >
                    <div style={{
                        height: 55,
                        width: 55,
                        lineHeight: '55px',
                        borderRadius: '50%',
                        backgroundColor: '#808080',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: 20,
                    }}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </div>
                </BackTop>
                <div className="mb-20">
                    <NavBar
                        role={this.props.globalData.role}
                        authorized={this.props.globalData.authorized}
                        displayName={this.props.globalData.displayName}
                        username={this.props.globalData.username}
                    />
                </div>
                {  this.props.pageTitle ?
                    this.props.pageTitle === 'Homepage' ?
                    <div className="w-full rounded-3xl shadow-2xl bg-white flex flex-col">
                        { this.props.content() }
                    </div> :
                    <div className='-mt-1 w-full bg-gray-100 px-2 sm:px-4 md:px-6 lg:px-24 lg:pb-24'>
                        <div className="-ml-4 p-5 w-full">
                            <div id="realNav" className="nav flex flex-wrap fixed bg-white z-40">
                                {
                                    Object.values(this.state.history).length > 0 && Object.keys(this.state.history).map( (keys) => (
                                   <div>
                                        <div className={ keys }>
                                            {Number(keys) < Number(Object.values(this.state.history).length) &&
                                                <div className="flex flex-wrap w-full">
                                                    <div className= {"px-2"}>
                                                        <a className={(Number(keys) === Number(Object.values(this.state.history).length) - 1 ? 'text-blue-700 font-bold' : 'text-black') + " block p-2"}
                                                           href={this.state.history[keys]['link'].toString().match('homepage') ? '/' : this.state.history[keys]['link']}>

                                                            <div>
                                                                {this.state.history[keys]['title'] }
                                                            </div>

                                                        </a>
                                                    </div>
                                                    <div>
                                                        {Number(keys) < Number(Object.values(this.state.history).length)-1 &&
                                                            <div className="block p-2">
                                                                  {'>'}
                                                           </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div id="posNav" className="nav flex flex-wrap bg-white relative -z-10 -mt-6">
                            {
                                Object.values(this.state.history).length > 0 && Object.keys(this.state.history).map( (keys) => (
                                    <div>
                                        <div className={ keys }>
                                            {Number(keys) < Number(Object.values(this.state.history).length) &&
                                                <div className="flex flex-wrap w-full">
                                                    <div className= {"px-2"}>
                                                        <a className={(Number(keys) === Number(Object.values(this.state.history).length) - 1 ? 'text-blue-700 font-bold' : 'text-black') + " block p-2"}
                                                           href={this.state.history[keys]['link'].toString().match('homepage') ? '/' : this.state.history[keys]['link']}>

                                                            <div>
                                                                {this.state.history[keys]['title'] }
                                                            </div>

                                                        </a>
                                                    </div>
                                                    <div>
                                                        {Number(keys) < Number(Object.values(this.state.history).length)-1 &&
                                                            <div className="block p-2">
                                                                {'>'}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                        <div id="titlePageNow"
                            className="text-2xl font-bold text-black px-2 sm:px-4 md:px-6 lg:px-24 py-4">{this.props.pageTitle ? this.props.pageTitle : ''}
                            <span id="successTitleMsg1" className="block sm:inline mx-2 px-2 text-green-600 text-sm"></span>
                            <span id="successTitleMsg" className="block sm:inline mx-2 px-2 text-green-600 text-sm"></span>
                            <span id="errorTitleMsg" className="block sm:inline mx-2 px-2 text-green-400 text-sm"></span>
                        </div>
                        <div id="templateContents" className="rounded-3xl shadow-2xl bg-white flex flex-col p-1 sm:p-2 md:p-4 lg:p-6">
                            {this.props.content() }
                        </div>
                    </div>
                    : ''
                }
                <Suspense fallback ={<div className="footerLoading"></div>}>
                    <Footer/>
                </Suspense>
            </div>
        );
    }

}